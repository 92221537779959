import {
  Box,
  Flex,
  FlexProps,
  Icon,
  Img,
  Link,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
  useColorModeValue,
  useMenuButton,
} from '@chakra-ui/react';
import { useWindowWidth } from '@react-hook/window-size';
import { GeneratorsAIExpandedSVGIcon } from "components/sidebar/icons/GeneratorsAIExpandedSVGIcon";
import { getPlan } from "controllers/subscription";
import { useSession } from 'next-auth/react';
import NextLink from "next/link";
import * as React from 'react';
import { textStyle } from 'theme/names';
import { getAccount } from 'utils/sessionHelper';

const capitalize = (s) => (s && s[0].toUpperCase() + s.slice(1)) || "";

const TooltipCTA = ({ label}) => {
  return (
    <Link 
      {...{
        as: NextLink,
        mt: .5,
        textDecoration: "underline",
        textUnderlineOffset: "3px",
        href: "/pricing",
        target: "_blank",
        
      }}
    >
      {label}
    </Link>
  );
}

export const AccountSwitcherButton = (props: FlexProps & any) => {
  let { disableMenu, disablePhoto, disableName = false, ...rest } = props;
  const { data: session, status } = useSession() ?? {}
  const loading = status === "loading"
  const buttonProps = useMenuButton(rest);
  // console.log(props.user);
  const [buttonHover, buttonHoverSet] = React.useState(false);
  
  const screenWidth = useWindowWidth();
  const isTooSmall = screenWidth < 800;

  // TODO refactor?
  const borderColor = useColorModeValue(
    "border.lightMode.light",
    "border.darkMode.light"
  );
  const borderColorHover = useColorModeValue(
    "border.lightMode.hover",
    "border.darkMode.hover"
  );
  const standardColor = useColorModeValue(
    "text.lightMode.standard",
    "text.darkMode.standard"
  );
  const lightColor = useColorModeValue(
    "text.lightMode.light",
    "text.darkMode.light"
  );

  let nbSeats = session?.user?.data?.members?.length ?? 1;
  const { creditsBestai } = getAccount(session);
  const plan = getPlan(session?.user);

  const tooltipLabel = {
    enterprise: (
      <>
        <Text>
          {`You have ${creditsBestai} GPT-4o out of 5,000 credits left this month. When making an AI request, you consume a credit. You will be switched to GPT-4o-mini when you run out of credits.`}
        </Text>
        {/* UNCOMMENT WHEN PAGE EXISTS */}
        {/* <Link as={NextLink} href='' target={"_blank"} textDecoration={"underline"} textUnderlineOffset="3px">Learn More</Link> */}
      </>
    ),
    grow: (
      <>
        <Text>
          {`You have ${creditsBestai} GPT-4o out of 250 credits left this month. When making an AI request, you consume a credit. You will be switched to GPT-4o-mini when you run out of credits.`}
        </Text>
        <TooltipCTA
          {...{ label: '🚀 Upgrade to Pro & get 5,000 GPT-4o credits /mo' }}
        />
        {/* UNCOMMENT WHEN PAGE EXISTS */}
        {/* <Link as={NextLink} href='' target={"_blank"} textDecoration={"underline"} textUnderlineOffset="3px">Learn More</Link> */}
      </>
    ),
    start: (
      <>
        <Text>
          {`You have ${creditsBestai} GPT-4o out of 0 credits left this month. When making an AI request, you consume a credit. You will be switched to GPT-4o-mini when you run out of credits.`}
        </Text>
        {/* <Link as={NextLink} href='/pricing' target={"_blank"} textDecoration={"underline"} textUnderlineOffset="3px" onClick={()=>{console.log("click");
        }}> */}
        <TooltipCTA {...{ label: '🚀 Upgrade to Standard or Pro' }} />
        {/* UNCOMMENT WHEN PAGE EXISTS */}
        {/* <Link as={NextLink} href='' target={"_blank"} textDecoration={"underline"} textUnderlineOffset="3px">Learn More</Link> */}
      </>
    ),
  };

  const AccountDisplay = () => {
    return (
      <Flex
        as="button"
        {...buttonProps}
        display="flex"
        w="full"
        alignItems="center"
        rounded="lg"
        p="2"
        fontSize="sm"
        userSelect="none"
        cursor="pointer"
        outline="0"
        transition="all 0.2s"
        border="1px solid"
        borderColor={borderColor}
        _hover={{ borderColor: borderColorHover }}
        // hover state
        onMouseEnter={() => buttonHoverSet(true)}
        onMouseLeave={() => buttonHoverSet(false)}
      >
        {!disablePhoto && (
          <Flex
            h="40px"
            w="100%"
            justifyContent={"flex-start"}
            alignItems="center"
          >
            <Img
              w="10"
              h="10"
              rounded="3xl"
              objectFit="cover"
              src={getAccount(session)?.image}
              alt="Chakra UI"
              mr={disableName ? 5 : 2}
            />
            <Flex flexDirection={"column"}>
              {!disableName && (
                <Box textAlign="start">
                  <Text
                    noOfLines={1}
                    wordBreak={"break-all"}
                    w="140px"
                    as="h3"
                    size="sm"
                    fontWeight={"600"}
                    fontSize={"sm"}
                    color={standardColor}
                  >
                    {getAccount(session)?.name}
                  </Text>
                </Box>
              )}
              <Flex gap={2}>
                <Text
                  textStyle={textStyle["small.medium.light"]}
                  fontSize="12px"
                  textAlign={"start"}
                >
                  {/* Type of plan */}
                  {/* {`${capitalize(getAccount(session)?.subscription?.plan)} Plan`} */}
                  {/* {`${session?.user?.data?.members?.length ?? 1} Seats`} */}
                  {`${nbSeats} Seat${nbSeats > 1 ? "s" : ""}`}
                </Text>
                <Flex gap={1} alignItems="center" justifyContent={"center"}>
                  <Icon
                    h={"14px"}
                    w={"14px"}
                    mt={"-2px"}
                    mr={"-1px"}
                    viewBox="0 0 16 16"
                    filter={"brightness(1.35)"}
                  >
                    {GeneratorsAIExpandedSVGIcon}
                  </Icon>
                  <Text
                    textStyle={textStyle["small.medium.light"]}
                    fontSize="12px"
                    textAlign={"start"}
                    flex={1}
                  >
                    {creditsBestai}
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        )}
        {!props.disableMenu && (
          <Box fontSize="md" color={buttonHover ? standardColor : lightColor}>
            {/* <FiMoreHorizontal /> */}
            <svg
              width="12"
              height="13"
              viewBox="0 0 12 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.88281 5.75H9.09375C9.65625 5.75 9.96094 5.07031 9.53906 4.64844L6.44531 1.46094C6.32812 1.32031 6.16406 1.25 6 1.25C5.83594 1.25 5.64844 1.32031 5.53125 1.46094L2.4375 4.64844C2.01562 5.07031 2.32031 5.75 2.88281 5.75ZM9.09375 7.25H2.88281C2.32031 7.25 2.01562 7.95312 2.4375 8.375L5.53125 11.5625C5.64844 11.7031 5.8125 11.75 6 11.75C6.16406 11.75 6.32812 11.7031 6.44531 11.5625L9.53906 8.375C9.96094 7.95312 9.65625 7.25 9.09375 7.25Z"
                fill="currentColor"
              />
            </svg>
          </Box>
        )}
      </Flex>
    )
  }

  if (isTooSmall) {
    return AccountDisplay();
  }

  return (
    // button
    <Popover trigger="hover" placement="right" >
      <PopoverTrigger>
          {AccountDisplay()}
        </PopoverTrigger>
        <Portal >
          <PopoverContent bg='blue.800' color="white" p={4} zIndex="999999999999999" left="10px" bottom="5px">
              {tooltipLabel[plan]}
          </PopoverContent>
        </Portal>
    </Popover>
  );
};
