import {
  Menu,
  MenuDivider,
  MenuItem,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  useColorModeValue,
  useColorMode,
  Center,
  Switch,
  Stack,
  Flex,
  Box,
  Button,
  Img,
  Image,
  Heading,
  Text,
  Input,
  FormControl,
  IconButton,
  useDisclosure,
  Grid,
  useMediaQuery,
} from "@chakra-ui/react";
import Payment from "components/payment";
import SubscriptionManagement from "components/subscriptionManagement";
import * as React from "react";
import { AccountSwitcherButton } from "./account-switcher-button";
import { useRouter } from "next/dist/client/router";
import { useSession, signOut } from "next-auth/react";
import { FiSettings, FiUnlock } from "react-icons/fi";
import { ImCross } from "react-icons/im";
import { MoonIcon, SunIcon } from "@chakra-ui/icons";
import * as analytics from "../../utils/analytics";
import { getPlan } from "../../controllers/subscription";
import { TweetContext } from "../../context/tweetContext";
import { firebaseClient, getToken } from "firebaseClient";
import toast from "react-hot-toast";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { useFirebaseUser } from "../../utils/useFirebaseUser";
import { setInLocalStorage } from "../../utils/helpers";
import {
  getAccount,
  updateUser,
  getAuthUrl,
  selectAccount,
  logout,
} from "utils/sessionHelper";
import { ConfirmPopup } from "../popups/confirmPopup";
import { HiOutlineLogout } from "react-icons/hi";
import { NavSectionTitle } from "components/sidebar/mainNavItems";
import { useWindowWidth } from "@react-hook/window-size";
import { variant } from "theme/names";

const async = require("async");
const capitalize = (s) => (s && s[0].toUpperCase() + s.slice(1)) || "";

function AccountSwitcherMenuItem({ onClick, label, icon }) {
  return (
    <MenuItem
      onClick={() => {
        // onOpenConfirmAdd();
        // props.push("/settings?tab=team");
        onClick();
      }}
      rounded="md"
      my={0}
      py={2}
      px={1}
      {...{
        color: "text.lightMode.light",
        _hover: {
          color: "text.lightMode.standard",
          bg: "none",
        },
        _dark: {
          color: "text.darkMode.light",
          bg: "none",
          _hover: {
            color: "text.darkMode.standard",
          },
        },
      }}
      icon={icon}
      // icon={<AiOutlinePlusCircle fontSize="16px" />}
    >
      <Text fontSize="sm" fontWeight={500}>
        {label}
      </Text>
    </MenuItem>
  );
}

export const AccountSwitcher = ({
  user,
  disableMenu,
  disablePhoto = false,
  disableName = false,
}) => {
  const { data: session, status } = useSession() ?? {};
  const loading = status === "loading";
  const { colorMode, toggleColorMode } = useColorMode();
  const tweetContext: any = React.useContext(TweetContext);
  const [customSearch, setCustomSearch] = React.useState("");
  const [initialAccounts, setInitialAccounts] = React.useState<any>([]);
  const isAuthenticated = useFirebaseUser();
  const [refresh, refresher] = React.useState({});

  const [selectedAccount, setSelectedAccount] = React.useState<any>({});
  const {
    isOpen: isOpenConfirmDelete,
    onOpen: onOpenConfirmDelete,
    onClose: onCloseConfirmDelete,
  } = useDisclosure();
  const {
    isOpen: isOpenConfirmAdd,
    onOpen: onOpenConfirmAdd,
    onClose: onCloseConfirmAdd,
  } = useDisclosure();

  const router = useRouter();

  let accounts = session?.user?.data?.accounts?.filter(
    (x) => x.idAccount != session.user.uid && x.provider == "twitter"
  );

  React.useEffect(() => {
    if (isAuthenticated && accounts) {
      accounts?.forEach(async (acc) => {
        if (acc.needInit && acc.id !== session?.user?.uid) {
          selectAccount(session, acc, "owned");
          acc.needInit = false;
          const db = firebaseClient.firestore();
          await db
            .collection("users")
            .doc(session?.user?.uid)
            .collection("accounts")
            .doc(acc.id)
            .update({ needInit: false });
          router.push("/onboarding");
        } else if (acc.needInit) {
          const db = firebaseClient.firestore();
          await db
            .collection("users")
            .doc(session?.user?.uid)
            .collection("accounts")
            .doc(acc.id)
            .update({ needInit: false });
        }
      });
    }
  }, [isAuthenticated, session]);

  // TODO refactor? -> use textStyles with nameHelper
  const textStandard = useColorModeValue(
    "text.lightMode.standard",
    "text.darkMode.standard"
  );
  const textLight = useColorModeValue(
    "text.lightMode.light",
    "text.darkMode.light"
  );
  const bg = useColorModeValue("white", "#1E1E1E");
  const borderColor = useColorModeValue(
    "border.lightMode.light",
    "border.darkMode.light"
  );
  const borderColorHover = useColorModeValue(
    "border.lightMode.hover",
    "border.darkMode.hover"
  );

  let nbSeats = session?.user?.data?.members?.length ?? 1;

  const getItem = (acc, session, type = "shared", showSeats = false) => {
    return (
      <MenuItem
        key={"acc-" + acc.id}
        position="relative"
        onClick={async (e) => {
          // console.log(acc);
          if (acc.idParent === session.user.uid) {
            console.log("going to main account");
            session.user.selectedAccount = undefined;
            session.user.selectedAccountId = "";
            session.user.selectedAccountType = "";
            localStorage.removeItem("selectedAccountId");
            localStorage.removeItem("selectedAccount");
            localStorage.removeItem("selectedAccountType");
          } else {
            console.log("going to shared account");
            selectAccount(session, acc, type);
          }

          location.reload();
        }}
        borderRadius="8px"
        mb={2}
        py={1}
        px={1}
        bg="none"
        border={"1px solid"}
        borderColor={"border.lightMode.light"}
        _hover={{
          borderColor: "border.lightMode.hover",
        }}
        _dark={{
          borderColor: "border.darkMode.light",
          _hover: {
            borderColor: "border.darkMode.hover",
          },
        }}
      >
        {/* delete account icon */}
        {/* {true && ( */}
        {["owned", "shared"].includes(type) &&
          !acc?.isFromOrg &&
          acc.id !== getAccount(session)?.idAccount && (
            <IconButton
              as={Box}
              minW="24px"
              variant="secondary"
              size="xs"
              aria-label="delete account"
              icon={<ImCross fontSize="8px" />}
              position="absolute"
              right="5px"
              zIndex="10"
              onClick={async (e) => {
                // console.log("delete account");
                // console.log(session?.user?.uid);
                // console.log(getAccount(session)?.id);
                // console.log(getAccount(session)?.idAccount);
                e.stopPropagation();
                if (session?.user?.uid !== getAccount(session)?.id) {
                  toast.error(
                    "Switch to your main account to remove shared accounts"
                  );
                  return;
                }
                setSelectedAccount(acc);
                onOpenConfirmDelete();
              }}
            />
          )}
        <Image
          w="10"
          h="10"
          rounded="3xl"
          objectFit="cover"
          src={acc.image}
          fallbackSrc="/emptyProfile.png"
        />
        <Flex flexDirection="column" ml={2}>
          <Text
            noOfLines={1}
            wordBreak={"break-all"}
            as="h2"
            fontSize="xs"
            color={textStandard}
            w="138px"
            flex={1}
            fontWeight={600}
          >
            {acc.name}
          </Text>
          {/* {true && ( */}
          {acc.twUserName && (
            <Text
              noOfLines={1}
              wordBreak={"break-all"}
              w="138px"
              color={textLight}
              fontSize="xs"
            >
              @{acc.twUserName}
            </Text>
          )}
          <Text color={textLight} fontSize="xs" textAlign={"start"}>
            {/* Type of plan */}
            {/* {`${capitalize(getAccount(session)?.subscription?.plan)} Plan`} */}
            {/* {`${session?.user?.data?.members?.length ?? 1} Seats`} */}
            {showSeats && `${nbSeats} Seat${nbSeats > 1 ? "s" : ""}`}
          </Text>
        </Flex>
      </MenuItem>
    );
  };

  // console.log(session?.user?.data?.accounts?.[0]);
  // console.log("id: " +  getAccount(session)?.id);
  // console.log("idAccount: " +  getAccount(session)?.idAccount);
  // console.log("email: " +  getAccount(session)?.email);
  // console.log(session?.user?.linkAccounts);

  // useColorModeValue

  const screenWidth = useWindowWidth();
  const isTooSmall = screenWidth < 800;

  return (
    <Menu offset={[0, 0]} placement="top">
      <AccountSwitcherButton
        user={user}
        disableMenu={disableMenu}
        disablePhoto={disablePhoto}
        disableName={disableName}
      />
      {!disableMenu && (
        // full width menu to prevent visual displacement of menu on hover when transitioning from navSize === "small" to navSize === "large"
        <MenuList
          background="none"
          border="none"
          shadow="none"
          // maxH="500px"
          // overflowY="auto"
          minW={isTooSmall ? "100vw" : "calc(250px - 0px)"}
          mb={1}
          maxH="80vh"
          overflowY="auto"
        >
          {/* the box is the actual visible menu (prevents visual displacement of menu on hover when transitioning from navSize === "small" to navSize === "large") */}
          <Box
            mb={1}
            fontSize={"sm"}
            color={"text.lightMode.light"}
            _dark={{
              color: "text.darkMode.light",
            }}
            shadow="2xl"
            p="2"
            ml={2}
            borderRadius={"md"}
            border={"1px solid"}
            borderColor={borderColor}
            bg={bg}
            margin="auto"
            maxW={isTooSmall ? "calc(100vw - 18px)" : "calc(250px - 18px)"}
            // maxH="60vh"
            overflowY="auto"
          >
            {/* user */}
            {getItem(
              session?.user?.data?.accounts?.[0] ?? session?.user?.data,
              session,
              "shared",
              true
            )}
            <AccountSwitcherMenuItem
              {...{
                onClick: () => {
                  router.push("/settings");
                },
                label: "Account settings",
                icon: <FiSettings fontSize="16px" />,
              }}
            ></AccountSwitcherMenuItem>
            {accounts?.length > 0 && (
              <>
                <NavSectionTitle
                  {...{
                    title: "YOUR ACCOUNTS",
                    navSize: "large",
                    compact: true,
                  }}
                ></NavSectionTitle>
                {accounts?.map((acc) => getItem(acc, session, "owned"))}
              </>
            )}
            {session?.user?.linkAccounts?.filter((x) => !x.isFromOrg)?.length >
              0 && (
              <>
                <NavSectionTitle
                  {...{
                    title: "SHARED ACCOUNTS",
                    navSize: "large",
                    compact: true,
                  }}
                ></NavSectionTitle>
                {session?.user?.linkAccounts
                  ?.filter((x) => !x.isFromOrg)
                  ?.map((acc) => getItem(acc, session))}
              </>
            )}
            <NavSectionTitle
              {...{
                title: "YOUR ORGANIZATION",
                navSize: "large",
                compact: true,
              }}
            />
            {session?.user?.linkAccounts?.filter((x) => x.isFromOrg)?.length >
              0 &&
              session?.user?.linkAccounts
                ?.filter((x) => x.isFromOrg)
                ?.map((acc) => getItem(acc, session))}
            {
              // if no org or org admin
              // true && (
              (!session?.user?.data?.subscription?.isInOrg ||
                session?.user?.data?.subscription?.orgRole === "Admin") && (
                <AccountSwitcherMenuItem
                  {...{
                    onClick: () => {
                      router.push("/settings?tab=team");
                    },
                    label: "Invite team members",
                    icon: <AiOutlinePlusCircle fontSize="16px" />,
                  }}
                ></AccountSwitcherMenuItem>
              )
            }
            {/* {
              session?.user?.linkAccounts?.length > 0 && (
                <>
                  <Text fontWeight="600" mt={3}>
                    Accounts shared
                  </Text>
                  {
                    session?.user?.linkAccounts?.map(acc => (
                      getItem(acc, session)
                    ))
                  }
                </>
              )
            } */}
            {/* {true && ( */}
            {session?.user?.data.isAdmin && (
              <Flex
                w="200px"
                // w="100%"
              >
                <Input
                  placeholder="email, id or username"
                  value={customSearch}
                  onChange={(e) => {
                    setCustomSearch(e.target.value);
                  }}
                  // isInvalid
                  // isDisabled
                />
                <Button
                  variant="secondary"
                  as={Box}
                  ml={2}
                  onClick={async () => {
                    console.log("look for " + customSearch);
                    toast.promise(
                      new Promise(async (resolve, reject) => {
                        if (session?.user?.data?.impersonatingOriginUser?.id) {
                          reject(
                            "You can't impersonate another user while impersonating another user"
                          );
                          return;
                        }

                        let response = await fetch("/api/impersonate/start", {
                          method: "POST",
                          headers: { "Content-Type": "application/json" },
                          body: JSON.stringify({
                            tokenUserId: session.user.uid,
                            token: await getToken(session, "checkTweetValid"),
                            userToImpersonate: customSearch,
                          }),
                        });
                        let data = await response.json();

                        if (data?.success) {
                          location.reload();
                          resolve(user);
                        } else {
                          reject(data.error ?? "An error occurred");
                        }
                      }),
                      {
                        loading: "loading ... ",
                        success: "Success",
                        error: (err) =>
                          err ? err.toString() : "An error occurred.",
                      }
                    );
                  }}
                >
                  Go
                </Button>
              </Flex>
            )}
            {(session?.user?.linkAccounts?.length > 0 ||
              session?.user?.data?.accounts?.length > 0) && (
              // true && (
              <MenuDivider />
            )}
            <AccountSwitcherMenuItem
              {...{
                onClick: () => {
                  logout();
                },
                label: "Logout",
                icon: (
                  <HiOutlineLogout
                    fontSize="16px"
                    style={{ marginLeft: "4px", marginRight: "-2px" }}
                  />
                ),
              }}
            ></AccountSwitcherMenuItem>

            {/* {false || false ? (
              false ? ( */}
            {session?.user?.data?.subscription?.isSubscribed || true ? (
              getPlan(session?.user) == "start" ? (
                <>
                  <MenuDivider />
                  <Button
                    as={Box}
                    variant={variant.Button.primary}
                    onClick={() => {
                      router.push("/pricing");
                    }}
                    css={{
                      width: "100%",
                    }}
                  >
                    Upgrade
                  </Button>
                </>
              ) : (
                <></>
              )
            ) : (
              <>
                <MenuDivider />
                <Button
                  as={Box}
                  variant={variant.Button.primary}
                  onClick={() => {
                    router.push("/pricing");
                  }}
                  css={{
                    width: "100%",
                  }}
                >
                  Start Free Trial
                </Button>
              </>
            )}
          </Box>
        </MenuList>
      )}
      <ConfirmPopup
        isOpen={isOpenConfirmDelete}
        onClose={onCloseConfirmDelete}
        title="Please confirm account removal"
        body={
          <>
            You’re about to remove the account:{" "}
            <b>
              {selectedAccount.twUserName
                ? "@" + selectedAccount.twUserName
                : selectedAccount.name}
            </b>
          </>
        }
        callback={async () => {
          if (selectedAccount?.id) {
            if (selectedAccount.type == "owned") {
              const db = firebaseClient.firestore();
              await db
                .collection("users")
                .doc(session?.user?.uid)
                .collection("accounts")
                .doc(selectedAccount.id)
                .delete();
              let docTweets = await db
                .collection("users")
                .doc(session?.user?.uid)
                .collection("tweets")
                .where("idAccount", "==", selectedAccount.id)
                .get();
              console.log("nb tweets to delete: " + docTweets.docs.length);
              // await async.mapLimit(docTweets.docs, 10, async (doc) => {
              docTweets.forEach((doc) => {
                console.log("delete tweets " + doc.id);
                db.collection("users")
                  .doc(session?.user?.uid)
                  .collection("tweets")
                  .doc(doc.id)
                  .delete();
              });
              await new Promise((resolve) => setTimeout(resolve, 1000));
              location.reload();
            } else {
              let dataToSend = {
                idUser: selectedAccount.id,
                mode: "delete",
                idGhost: getAccount(session).id,
                tokenUserId: session?.user?.uid,
                token: await getToken(session, "sidebar-share-delete"),
              };
              let response = await fetch("/api/shareAccount", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(dataToSend),
              });
              let data = await response.json();
              if (data?.success && data.shareAccountsTo) {
                getAccount(session).shareAccountsTo = data.shareAccountsTo;
                refresher({});
                await new Promise((resolve) => setTimeout(resolve, 1000));
                location.reload();
              } else {
                toast.error("An error occurred: " + data?.error);
              }
            }
            // refresher({});
          }
        }}
      />
      <ConfirmPopup
        isOpen={isOpenConfirmAdd}
        onClose={onCloseConfirmAdd}
        title="Add a new account"
        body={
          "Please, make sure you are signed in on Twitter with the account you want to add"
        }
        cta="Connect"
        ctaProps={{
          as: "a",
          href: "#", //disable getAuthUrl - manoj - October 2024
          target: "_blank",
        }}
        callback={async () => {}}
      />
    </Menu>
  );
};
